import React from "react";
import {CodeActivation, CommunityVersionDownload} from '../components/DownloadSection'
import {ContainerBox} from "../styles/OrderStyle";
import {ButtonBox, ContainerInnerBox, HeadTitle} from "../styles/DownloadStyle";
import Typography from "@mui/material/Typography";

class Download extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <ContainerBox
            style={{display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              padding: "100px",
              margin: "100px 0px 0px 0px"}}>
          <CommunityVersionDownload/>
          <CodeActivation/>
        </ContainerBox>)
  }
}

export default Download;