import React from "react";
import {OrderComponent} from '../components/OrderSection';
import {useTranslation} from "react-i18next";
import {ContainerBox} from "../styles/OrderStyle";

function Order() {
    const [t, i18n] = useTranslation("default")

    return (
        <ContainerBox>
            <div
                style={{
                    alignContent: "center",
                    textAlign: "center",
                    width: "100%",
                    height: "64px",
                    backgroundColor: "#00c7ff",
                    margin: "100px 0 0 0"
            }}>
                <h2>
                    {t("order.banner")}
                </h2>
            </div>
            <OrderComponent/>
        </ContainerBox>
    )
}

export default Order;