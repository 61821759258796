import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { LinearProgress, Typography } from "@mui/material";

import {
    ContainerBox, ContainerInnerBox,
    HeadTitle, InputBox, CustomTextField, ButtonBox,
    Table, TableRow, TableHeader, TableCell
} from "../styles/DownloadStyle";
import axios from 'axios';
import gtag_report_conversion from "../lib/ConversionTracking";

function FeatureTable() {
    const [t, i18n] = useTranslation('default');
    const tableHeaders = t('download.feature_table_header', { returnObjects: true });
    const featureTable = t('download.feature_table', { returnObjects: true });

    return (
        <Table>
            {/* 테이블 헤더 */}
            <thead>
            <TableRow>
                {/* 헤더 배열을 map 함수로 순회하며 각 헤더 렌더링 */}
                {
                    tableHeaders.map((header, index) => (
                    <TableHeader key={index}>{header}</TableHeader>
                ))}
            </TableRow>
            </thead>

            {/* 테이블 바디 */}
            <tbody>
            {
                featureTable.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell>{row.feature}</TableCell>
                        <TableCell>{row.community}</TableCell>
                        <TableCell>{row.pro}</TableCell>
                    </TableRow>
                ))
            }
            </tbody>
        </Table>
    );
}

function CommunityVersionDownload() {
    const [t, i18n] = useTranslation('default');
    const [progress, setProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleSubmit = event => {
        gtag_report_conversion();
        setIsDownloading(true); // 다운로드 시작 상태 설정
        setProgress(0); // 진행률 초기화

        const data = {
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Disposition': 'attachment'
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round(progressEvent.loaded * 100 /
                    progressEvent.total);

                setProgress(percentCompleted)

            },
        };

        axios
            .post(`${process.env.REACT_APP_API_ROOT}/download/community`, data, config)
            .then((response) => {
                const filename = response.headers['content-disposition'].split(" filename=")[1]
                const blob = new Blob([response.data], {type: 'application/octet-stream'});
                const fileObjectUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = fileObjectUrl;
                link.style.display = "none";
                link.download = filename;

                // Add link on body and force trigger click event
                document.body.appendChild(link);
                link.click();
                link.remove();

                window.URL.revokeObjectURL(fileObjectUrl);
            })
            .catch(error => {
                alert(error.message)
                navigate("/error")
            })
            .finally(() => {
                setIsDownloading(false); // 다운로드 완료 상태로 변경
            });
    }

    return (
        <ContainerInnerBox
            style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center,",
                flex: 1,
                border: "#00c6ff",
                borderStyle: "solid"}}
        >
            <HeadTitle>{t("download.community_version")}</HeadTitle>
            <h2>{t("download.community_version_description")}</h2>
            <ButtonBox>
                <button type="submit"
                        onClick={handleSubmit}
                        className={'get-blurtle-button'}>
                    {t("download.btn_download")}
                </button>
            </ButtonBox>
            {isDownloading && (
                <div style={{justifyItems: "center"}}>
                    <LinearProgress variant="determinate"
                                    value={progress}
                                    style={{height: 20, width: "70%"}}/>
                    <Typography fontSize="32px">{progress}%</Typography>
                </div>
            )}
            <FeatureTable/>
        </ContainerInnerBox>
    );
}

function CodeActivation() {

    const [t, i18n] = useTranslation('default');
    const [licenseKey, setLicenseKey] = useState("")
    const [keyUnvalidness, setKeyUnvalidness] = useState(true);
    const navigate = useNavigate()
    const [progress, setProgress] = useState(0)
    const [isDownloading, setIsDownloading] = useState(false);

    const handleChange = event => {
        setLicenseKey(event.target.value);
    };

    const handleSubmit = event => {
        if (licenseKey.length == 0) {
            alert('Please input valid license key.')
        }

        const data = {
            "license_key": licenseKey
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Disposition': 'attachment'
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round(progressEvent.loaded * 100 /
                    progressEvent.total);

                setProgress(percentCompleted)
            },
        };

        setKeyUnvalidness(true)
        setIsDownloading(true); // 다운로드 시작 상태 설정

        axios
            .post(`${process.env.REACT_APP_API_ROOT}/download`, data, config)
            .then((response) => {
                if (response.status == "204"){
                    setKeyUnvalidness(true)
                    alert("Unvalid license key.")
                }
                else {
                    setKeyUnvalidness(false)
                    const filename = response.headers['content-disposition'].split(" filename=")[1]
                    const blob = new Blob([response.data], {type: 'application/octet-stream'});
                    const fileObjectUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = fileObjectUrl;
                    link.style.display = "none";
                    link.download = filename;

                    // Add link on body and force trigger click event
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    window.URL.revokeObjectURL(fileObjectUrl);
                }
            })
            .catch(error => {
                alert(error.message)
                navigate("/error")
            })
            .finally(() => {
                setIsDownloading(false); // 다운로드 시작 상태 설정
            })
    }

    return (
        <ContainerInnerBox
            style={{
                justifyItems: "center",
                flex: 1,
                border: "#00c6ff",
                borderStyle: "solid"}}
        >
            <div>
                <HeadTitle>{t("download.pro_version")}</HeadTitle>
                <h2>{t("download.head_title")}</h2>
            </div>

            <InputBox>
                <CustomTextField
                    placeholder={t("download.placeholder")}
                    onChange={handleChange}
                    value={licenseKey}
                />
            </InputBox>

            <ButtonBox>
                <button type="submit"
                        className={'get-blurtle-button'}
                        onClick={ handleSubmit }
                        disabled={isDownloading}>{t("download.btn_txt")}</button>
            </ButtonBox>
            <LinearProgress variant="determinate" value={progress}
                            style={{height: 20, width: "70%", margin: "0 50px 0 50px"}}/>
            <Typography>{progress.toString()}%</Typography>
        </ContainerInnerBox>
    );
}

export {CodeActivation, CommunityVersionDownload};