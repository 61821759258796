import React, {useState} from "react";
import { useNavigate } from 'react-router-dom'
import { BackgroundNeon, CatalogBackground1, SloganH1 } from "../styles/HomeSectionStyle";
import { useTranslation, Trans } from 'react-i18next';
import { LinearProgress, Typography } from "@mui/material";

import '../css/HomeSection.css';

import appScreen from '../assets/HomeSection/HomePage_01.png';
import AppScreenKR from '../assets/HomeSection/HomePage_01_KR.png';
import whatIsBlurtle from '../assets/HomeSection/What_is_Blurtle.png';
import FeatureFABImg from '../assets/HomeSection/HomePage_02.jpg';
import FeatureEditableImg from '../assets/HomeSection/Feature_Editable.png';
import ClockIcon from '../assets/HomeSection/clock.png';
import BlurIcon from '../assets/HomeSection/blur.png';
import AccuracyIcon from '../assets/HomeSection/accuracy.png';
import AddIcon from '../assets/HomeSection/add.png';
import EditIcon from '../assets/HomeSection/edit.png';
import DeleteIcon from '../assets/HomeSection/delete.png';
import axios from "axios";
import gtag_report_conversion from "../lib/ConversionTracking";

class ApplicationMainImage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {dimensions: {}};
        this.onImgLoad = this.onImgLoad.bind(this);
    }

    onImgLoad({target:img}) {
        this.setState({dimensions:{height:img.offsetHeight,
                width:img.offsetWidth}});
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const {src} = this.props;
        const width = (this.state.dimensions.width + 200) + 'px';
        const height = (this.state.dimensions.height + 200) + 'px';

        return (
            <div className={'ApplicationMainImage'}>
                <img onLoad={this.onImgLoad} src={src}/>
                {/* <BackgroundNeon width={width} height={height}/>
                <BackgroundNeon degree='180' width={width} height={height}/> */}
            </div>
        );
    }
}


function IntroSection() {
    const [t, i18n] = useTranslation('default');
    const navigate = useNavigate();

    const handleSubmit = event => {
        navigate("/download")
    }

    return (
        <div className={'intro-section'}>
            {
                /* FUTURE
                <ReleaseStatusLink/>
                */
            }
            <SloganH1>{t("home.slogan.slogan_1")}</SloganH1>
            <SloganH1 color="#267DCC">{t("home.slogan.slogan_2")}</SloganH1>
            <p className={"sub-slogan"}>{t("home.explain")}</p>
            <button className={'get-blurtle-button'}
                    onClick={handleSubmit}>
                {t("home.get_blurtle_now")}
            </button>
            {
                i18n.language.toLowerCase() === "ko" ? (
                    <ApplicationMainImage src={AppScreenKR}/>

                ) : (
                    <ApplicationMainImage src={appScreen}/>
                )

            }
            {
                /* FUTURE
                <PoweredBy/>
                */
            }
        </div>
    );
}

function CatalogueIntro() {
    const [t, i18n] = useTranslation('default');

    return (
        <div className={'catalog-introduce'}>
            <h1 style={{color:"#267DCC"}}><Trans t={t} i18nKey={"default:home.catalogue_intro.title"}/></h1>
            <div className={'catalog-section-row'}>
                <div className={'catalog-section-description'}>
                    <h1>
                        <Trans i18nKey={"default:home.catalogue_intro.what_is_blurtle"}/>
                    </h1>
                    <p style={{fontSize: "32px"}}><Trans i18nKey={"default:home.catalogue_intro.description"}/></p>
                </div>
                <div className={'image-with-background'}>
                    <img src={whatIsBlurtle}/>
                </div>
            </div>
        </div>
    );
}


function CatalogueFeature () {
    const [t, i18n] = useTranslation('default');

    return (
        <div className={'catalog-introduce'}>
            <div className={'catalog-section-row'}>
                <div className={'image-with-background'}>
                    <img src={FeatureFABImg} style={{objectFit: 'contain' }}/>
                </div>
                <div className={'catalog-section-description'}>
                    <img src={ClockIcon}/>
                    <h1>
                        <Trans i18nKey={"default:home.catalogue_feature.title_1"}/>
                    </h1>
                    <div style={{display:"grid", gap: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                            <img src={BlurIcon}/>
                            <p style={{fontSize: "32px"}}><Trans i18nKey={"default:home.catalogue_feature.description_1"}/></p>
                        </div>
                        <div style={{display: "flex", alignItems:"center",  gap: "20px"}}>
                            <img src={AccuracyIcon}/>
                            <p style={{fontSize: "32px"}}><Trans i18nKey={"default:home.catalogue_feature.description_2"}/></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'catalog-section-row'}>
                <div className={'catalog-section-description'}>
                    <h1>
                        <Trans i18nKey={"default:home.catalogue_feature.title_2"}/>
                    </h1>
                    <div style={{display: "flex", alignItems:"center",  gap: "20px"}}>
                        <img src={AddIcon}/>
                        <img src={EditIcon}/>
                        <img src={DeleteIcon}/>
                    </div>
                    <p style={{fontSize: "32px"}}><Trans i18nKey={"default:home.catalogue_feature.description_3"}/></p>
                </div>
                <div className={'image-with-background'}>
                    <img src={FeatureEditableImg}/>
                </div>
            </div>
        </div>
    );
}


export {
    IntroSection,
    CatalogueIntro,
    CatalogueFeature
};