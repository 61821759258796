import styled, { keyframes } from 'styled-components';
import { TextField } from '@mui/material';

// export const StyledCenterBoldH1 = styled.h1`
//   display: flex;
//   justify-content: center;
//   font-family: 'Inter';
//   row-gap: 50px;
// `;

// export const StyledCenterDiv = styled.div`
//     display: grid;
//     justify-content: center;
//     justify-items: center;
//     gap: 40px;
// `;

// export const StyledSubmitButton = styled.input`
//   align-self: end;
//   font-size: 28px;
//   max-width: 300px;
//   max-height: 150px;
//   justify-content: center;
//   align-items: center;
//   padding: 0px;
//   padding: 10px 30px 10px 30px;
//   background: #1F93C6;
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 40px;
//   color: white;
//   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
//   border-radius: 22px;
//   border-color: transparent;
  
//   &:hover {
//     background-color : whitesmoke;
//     color: #1F93C6;
//   }
// `;

// export const Trembling = keyframes`
//   0% {
//     transform : translateX(-10px)
//   }
//   25% {
//     transform : translateX(0px)
//   }
//   50% {
//     transform : translateX(10px)
//   }
//   75% {
//     transform : translateX(0px)
//   }
//   100% {
//     transform : translateX(-10px)
//   }
// `

// export const TremblingH3 = styled.h3`
//   color: #be3050;
//   visibility: ${props => props.play ? "visible" : "hidden" };
//   animation: ${Trembling} 500ms linear 3;
// `


export const ContainerBox = styled.main`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(208, 244, 255, 0) 0%, #a6e4f6 99.36%);
`;

export const ContainerInnerBox = styled.div`
  max-width: 1140px;
  margin: 0 auto 100px;
  text-align: center;
`;

export const HeadTitle = styled.h1`
  padding: 100px 20px 30px 20px;
  word-break: keep-all;
  font-family: 'Inter';
  font-style: normal;
  font-size: 46px;
  font-weight: 800;
  color: #00BEF2;
`

export const InputBox = styled.div`
    width: 70%;
    margin: 100px 0 0 0;
`

export const CustomTextField = styled(TextField)({
  width: "70%",
  height: "70px",
  background: "#ffffff",
  border: "none",
  borderRadius: "20px",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.25)",

  "& fieldset": {
      border: "1px solid #ffffff",
      borderRadius: "20px",
  },

  "& .MuiInputBase-root": {
      height: "100%",

      "& input": {
          fontSize: "15px",
          textAlign: "center",

          "&::placeholder": {
              fontSize: "15px",
          },
      },

      "&:hover fieldset": {
          border: "1px solid #ffffff",
          borderRadius: "20px",
      }, 

      "&.Mui-focused fieldset": {
          border: "1px solid #00BEF2",
          borderRadius: "20px",
      }

  }
});

export const ButtonBox = styled.div`
  width: 200px;
  margin: 50px auto;

  button {
    width: 100%;
    height: 56px;
    padding: 0 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    background: #00BEF2;
    border: 1px solid #00BEF2;
    border-radius: 20px;
  }
`

// 표의 전체 레이아웃과 스타일을 정의
export const Table = styled.table`
  width: 80%;
  margin: 50px auto; /* 가운데 정렬 */
  border-collapse: collapse; /* 경계선 겹침 방지 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  font-family: Arial, sans-serif;
  font-size: 16px;
`;

// 테이블 헤더 스타일
export const TableHeader = styled.th`
  background-color: #4CAF50; /* 상단 헤더 배경색 */
  color: white; /* 텍스트 색상 */
  padding: 12px 15px;
  border: 1px solid #ddd; /* 경계선 */
  text-align: center;
`;

// 일반적인 행의 스타일
export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2; /* 짝수 행의 배경색 */
  }

  &:hover {
    background-color: #ddd; /* 마우스 호버 시 배경색 */
  }
`;

// 테이블 셀 스타일
export const TableCell = styled.td`
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
`;