import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useTranslation, Trans } from "react-i18next";

export const ContainerBox = styled.main`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(208, 244, 255, 0) 0%,
    #a6e4f6 99.36%
  );
  // background: linear-gradient(180deg, rgba(208, 244, 255, 0) 0%, #D0F4FF 99.36%);
`;

export const ContainerInnerBox = styled.div`
  max-width: 1140px;
  margin: 0 auto 100px;
  text-align: center;
`;

export const HeadTitle = styled.h1`
  padding: 100px 20px 30px 20px;
  word-break: keep-all;
  align-items: stretch;
  font-family: "Inter";
  font-style: normal;
  font-size: 46px;
  font-weight: 800;
  color: #00bef2;
`;

export const InputBox = styled.div`
  display: inline-block;
  width: 50%;
  padding: 10px 20px;
  text-align: left;

  input {
    border: 1px solid #e7e6e700;
    border-radius: 5px;
    font-size: 16px;
    line-height: 32px;
  }

  .email {
    width: 100%;
    outline-style: solid;
    outline-color: #aaaaaa30;
    box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
  }

  .nameBox {
    display: inline-block;
    width: 50%;
  }

  .nameBox input {
    width: 70%;
    outline-style: solid;
    outline-color: #aaaaaa30;
    box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
  }

  .nameBox label {
    padding-left: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }
`;

export const InputTitle = styled.div`
  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }
`;

export const ProceedBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 20px 20px;
  padding: 15px 50px;
  background: #ffffff;

  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;

  span {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
  }

  .black {
    color: #000000;
  }

  .blue {
    color: #00bef2;
  }

  button {
    width: 160px;
    height: 40px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    background: #00bef2;
    border: 1px solid #00bef2;
    border-radius: 20px;
  }

  .portone-ui-container {
    display: none;
  }
`;

export const AgreeInputBox = styled.div`
  margin: 40px;

  display: grid;
  grid-template-columns: 8fr 2fr;
  input {
    margin-right: 7px;
  }

  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
    text-align: left;
  }
`;

export const NotiBox = styled.div`
  margin: 40px 20px 0 20px;
  padding: 20px 40px;
  outline-style: solid;
  outline-color: #aaaaaa30;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);

  background: rgb(255 255 255 / 49%);
  border-radius: 20px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #484848;

  h2 {
    font-size: 16px;
    font-weight: 700;
  }

  li {
    line-height: 32px;
  }

  .refundContentsBox {
    margin: 15px 0;
    p {
      margin: 0;
    }
  }
`;

function NotiBoxListItem(props) {
  return <li>{props.text}</li>;
}

function NotiBoxParagraphItem(props) {
  return <li style={{ whiteSpace: "pre-wrap", fontSize: "20px" }}>{props.text}</li>;
}

export { NotiBoxListItem, NotiBoxParagraphItem };
